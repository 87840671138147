
import { defineComponent, onMounted,onBeforeMount,ref } from "vue";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTListWidget5 from "@/components/widgets/lists/Widget5.vue";
import KTTableWidget5 from "@/components/widgets/tables/Integrations.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { Integration } from "@/dtos/Integration";
import { LogDTO } from "@/dtos/LogDTO";
const toast = useToast();


export default defineComponent({
  name: "account-overview",
  components: {
    KTChartWidget1,
    KTListWidget5,
    KTTableWidget5,
    KTListWidget1,
  },
  setup() {
    const store = useStore();
    const Integrations = ref(null);
    const Logs = ref(null);
    const refreshData=  async ()=>{
      await store.dispatch(Actions.GETINTEGRATIONS).then((response: Integration[]) => {
        Integrations.value = response as any ;
      });
    }
    const refreshLogs =async () => {
      await store.dispatch(Actions.GETLOGS).then((response:LogDTO[])=>{
        Logs.value = response as any;
      })
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs("Genel", ["Hesap Bilgileri"]);
    });
    onBeforeMount(async () => {
       refreshData();
       refreshLogs();
    
    });
    return {
      Integrations,
      Logs,
      refreshData,
      refreshLogs
    };
  },
});
